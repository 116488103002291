
import { useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription } from "@/Components/ui/sheet"
import { Button } from "@/Components/ui/button";
import { FormSwitchGroup } from "./FormGroup";
import { useLocalStorageState } from "ahooks";
import { Icon } from "@iconify/react";


const ManageCookies = () => {
    const [cookiesModal, setCookiesModal] = useLocalStorageState('cookies-modal', {
        defaultValue: true
    })
    const [analytic_cookies, setAnalyticCookies] = useLocalStorageState('analytic_cookies', {
        defaultValue: true
    })
    const [marketing_cookies, setMarketingCookies] = useLocalStorageState('marketing_cookies', {
        defaultValue: true,
    });
    const savePreferences = () => {
        setCookiesModal(false);
    };

    return (
        <Sheet open={cookiesModal} onOpenChange={setCookiesModal}>
            <SheetContent side="bottom" closable={false} hasOverlay={false} onInteractOutside={(e) => e.preventDefault()} onEscapeKeyDown={(e) => e.preventDefault()}
                className="bg-white shadow-lg max-w-7xl mb-2 mx-auto w-full border border-gray-200 rounded-lg"
                style={{ backdropFilter: 'none' }}
            >
                <SheetHeader>
                    <SheetTitle>About Our Cookies</SheetTitle>
                    <SheetDescription className="text-primary">
                        We use cookies to ensure our website works the way you expect, and to help us better understand how our site performs. Cookies may also be used by our advertising partners to display relevant ads to you on other sites. You can manage your cookies permission below.
                    </SheetDescription>
                </SheetHeader>
                <div className="flex relative md:flex-row flex-col md:pr-4 p-0 justify-center items-center gap-4">
                    <Icon icon="proicons:cookies" width="150" height="150" className="absolute opacity-10 -top-[100px] left-1/2" />
                    <div className="flex w-full md:flex-row flex-col gap-4 md:justify-start justify-center items-center">
                        <FormSwitchGroup disabled checked={true} label="Essential Cookies" className="form-checkbox" />
                        <FormSwitchGroup checked={analytic_cookies} onCheckedChange={(v) => setAnalyticCookies(v)} label="Analytics Cookies" className="form-checkbox" />
                        <FormSwitchGroup checked={marketing_cookies} onCheckedChange={(v) => setMarketingCookies(v)} label="Marketing Cookies" className="form-checkbox" />
                    </div>
                    <div className="flex justify-end  space-x-2">
                        <Button variant="theme" className="px-6 rounded-full" size="sm" onClick={savePreferences}>
                            Save Preferences
                        </Button>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
};

export default ManageCookies;



import ApplicationLogo from "@/Components/ApplicationLogo";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";
import { setLoader } from "@/Hooks/Functions";
import { Icon } from "@iconify/react";
import { Link, useForm } from "@inertiajs/react";
import { toast } from "sonner";

export default function Foooter({ auth }) {

    const { data, setData, post, reset } = useForm({
        email: auth?.user?.email || '',
    });

    const submit = () => {
        post(route('newsletter.subscribe'), {
            onStart: () => setLoader(true, 'Subscribing...'),
            onSuccess: () => { toast.success('Subscribed successfully'); reset(); },
            onError: (error) => toast.error(error.email),
            onFinish: () => { setLoader(false); }
        });
    }
    return (
        <div className="w-full relative min-h-[40vh] mt-8 rounded-2xl flex flex-col divide-y justify-center items-center bg-card-foreground ">
            <div className="md:max-w-7xl w-full flex md:flex-row flex-col justify-evenly items-stretch p-4 gap-4">
                <div className="md:w-fit w-full flex text-white flex-col justify-start items-center gap-2 p-4">
                    <ApplicationLogo data-aos="fade-down" white square responsive={false} className="h-[80px] w-fit" />
                    <div data-aos="fade-down" className="flex flex-row justify-center items-center gap-3 py-4 mb-4">
                        <Icon icon="fa6-brands:facebook-f" className="text-2xl" />
                        <Icon icon="fa6-brands:x-twitter" className="text-2xl" />
                        <Icon icon="fa6-brands:instagram" className="text-2xl" />
                        <Icon icon="fa6-brands:youtube" className="text-2xl" />
                    </div>
                </div>
                <div className="md:w-1/3 w-full flex flex-row flex-nowrap gap-4 text-white">
                    <div className="basis-1/2 flex flex-col items-start justify-start gap-4 text-white">
                        <h2 data-aos="fade-down" className="font-semibold">Customer Care</h2>
                        <Link data-aos="fade-down" href={route('about-us')} className="hover:underline">About Us</Link>
                        <Link data-aos="fade-down" href={route('contact-us')} className="hover:underline">Contact Us</Link>
                        <Link data-aos="fade-down" href={route('help-center')} className="hover:underline">Help Center</Link>
                        <Link data-aos="fade-down" href={route('affiliate-program')} className="hover:underline">Affilliates Program</Link>
                    </div>
                    <div className="basis-1/2 flex flex-col items-start justify-start gap-4 text-white">
                        <h2 data-aos="fade-down" className="font-semibold">More</h2>
                        <Link data-aos="fade-down" href={route('blogs')} className="hover:underline">Blogs</Link>
                        <Link data-aos="fade-down" href={route('faqs')} className="hover:underline">FAQs</Link>
                        <Link data-aos="fade-down" href={route('prompt.showcase')} className="hover:underline">Prompt Showcase</Link>
                        <Link data-aos="fade-down" href={route('ai-reference.index')} className="hover:underline">Prompt Reference</Link>
                    </div>
                </div>
                <div data-aos="fade-down" className="md:w-1/3 w-full flex flex-col items-center gap-4 text-white md:border-t-0 border-t md:pt-0 pt-4">
                    <h2 className="text-center">Newsletter</h2>
                    <p className="text-center">
                        Sign up our newsletter to receive updates from our
                        store, promotions, and events.
                    </p>
                    <div className="flex flex-row relative justify-between flex-nowrap items-stretch">
                        <Input className="rounded-l-full text-center" type="email" value={data.email} onChange={(e) => setData('email', e.target.value)} placeholder="Enter your email address" />
                        <Button className="rounded-l-none flex-grow h-full min-h-10 px-6" disabled={!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)} onClick={submit} variant="theme">Submit</Button>                    </div>
                </div>
            </div>
            <div className="w-full md:absolute bottom-4   md:divide-x text-white flex md:flex-nowrap flex-wrap flex-row justify-center items-stretch p-4 gap-4">
                <span className="px-4">
                    Copyright © {new Date().getFullYear()}{" "}
                </span>
                <Link href={route("home")} className="px-4 hover:underline">
                    rockmyprompt.com
                </Link>
                <Link href={route('legalities', { page: 'privacy-policy' })} className="px-4 hover:underline">Privacy & Policy</Link>
                <Link href={route('legalities', { page: 'terms-of-service' })} className="px-4 hover:underline">Term of Services</Link>
            </div>
        </div>
    );
}
